@tailwind base;

/* my resets */
html, body {
  min-height: 100vh;
  --reading-font: Cambria, Perpetua, serif;
  font-size: 18px;
  scroll-behavior: smooth;
}

body {
  @apply relative pt-24 pb-8 font-sans text-gray-800 bg-gray-200;
}

a {
  color: inherit;
}

hr {
  @apply my-4 border-b;
}

input::placeholder {
  @apply font-light;
}

svg {
  fill: currentColor;
}

/* my resets end */


@tailwind components;

/* my components */

.link {
  @apply font-normal text-blue-600 underline cursor-pointer;
}
.link:hover, .link:active {
  @apply text-blue-500;
}

.markdown {
  @apply py-2 text-lg text-left;
  /*font-family: var(--reading-font);*/
  font-family: 'Open Sans';
}

.markdown > p {
  @apply px-4 leading-relaxed;
}

.markdown > h1 {
  @apply px-4 mt-4 mb-8 text-4xl font-semibold leading-relaxed tracking-wide text-center text-gray-800;
}

.markdown > h1::after {
  @apply block w-full h-1 bg-gradient-to-r from-transparent via-green-800 to-transparent;
  content: " ";
}

.markdown > h2 {
  @apply px-4 mt-8 mb-4 text-2xl font-bold leading-relaxed tracking-wide;
}

.markdown > h2::after {
  @apply block w-full h-0.5 bg-gradient-to-r from-gray-800 via-transparent to-transparent;
  content: " ";
}

.markdown > h3 {
  @apply px-4 mt-6 mb-3 text-xl font-bold tracking-wide;
}

.markdown a {
  @apply text-blue-600;
}

.markdown a:hover {
  @apply underline;
}

.markdown > p, .markdown > ul, .markdown > blockquote, .markdown > pre > code {
  @apply mt-2 mb-4;
}

.markdown > blockquote {
  @apply p-2 pl-4 text-2xl bg-gray-200 border border-l-4 border-gray-400;
  font-family: Garamond, serif;
}

/*.markdown > blockquote > p {*/
  /*quotes: '“' '”' '‘' '’'; [> opening followed by closing quote pairs <]*/
/*}*/
/*.markdown > blockquote > p:before {*/
  /*@apply text-4xl;*/
  /*content: open-quote;*/
/*}*/
/*.markdown > blockquote > p:after {*/
  /*content: close-quote;*/
/*}*/

/* triple backticks */
.markdown pre > code {
  @apply block px-4 overflow-x-auto font-mono text-sm shadow-md;
}

/* single backticks */
.markdown :not(pre) > code {
  @apply inline-block px-1 font-mono text-sm tracking-tight text-red-800 bg-gray-200 rounded;
}

.markdown :not(pre) a > code {
  @apply text-blue-600;
}

.markdown :not(pre) a:hover > code {
  @apply underline;
}

.markdown ul, .markdown ol {
  @apply pl-8;
}

.markdown > ul {
  @apply list-disc;
}

.markdown > ol {
  @apply list-decimal;
}

.markdown li + li {
  @apply mt-2;
}

.markdown > ul > li > ol {
  @apply text-base list-decimal;
}

.markdown > ul > li > ol > li + li {
  @apply mt-0;
}

.markdown > p > img {
  @apply mx-auto shadow-md;
}

.markdown > p > img + em {
  @apply block mt-1 font-sans text-xs text-center;
}

/* my components end */

@tailwind utilities;

/* put custom utilities here */

.height-minus-header-and-footer {
  min-height: calc(100vh - 4.717rem - 1.6rem);
}

.header-gradient {
  background-image: linear-gradient(180deg, theme('colors.green.900'), theme('colors.green.700'));
}

.collapsed {
  border-collapse: collapse;
}

.quoted {
  quotes: '“' '”' '‘' '’'; /* [> opening followed by closing quote pairs <] */
}
.quoted::before {
  content: open-quote;
}
.quoted::after {
  content: close-quote;
}
